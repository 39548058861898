
<template>
  <div style="width: 100%">
    <v-container class="home-container">
      <div class="d-flex justify-space-between intro-container">
        <div></div>
        <div
          class="d-flex flex-column text-center"
          :class="$vuetify.breakpoint.smAndUp ? 'intro-frame' : 'intro-frame-sm'"
        >
          <div
            class="mb-6"
            :class="$vuetify.breakpoint.smAndUp ? 'text-h3 font-weight-black' : 'text-h4 font-weight-bold'"
          >
            Useful Tool in The Crypto World
          </div>
          <div class="text-h5 text-h5 mb-12 font-weight-regular">B - Tool bring to you 3 usefull tool</div>
          <div :class="$vuetify.breakpoint.smAndUp ? '' : 'd-flex justify-center'">
            <div
              class="d-flex flex-sm-row flex-column text justify-space-between mx-6"
              :class="$vuetify.breakpoint.smAndUp ? 'align-center' : ''"
            >
              <div class="d-flex text-h5" :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-2'">
                <v-icon color="success lighten-2" class="mr-2">mdi-check-circle-outline</v-icon>
                Effective
              </div>
              <div class="d-flex text-h5" :class="$vuetify.breakpoint.smAndUp ? '' : 'mb-2'">
                <v-icon color="success lighten-2" class="mr-2">mdi-check-circle-outline</v-icon>
                Saving fee
              </div>
              <div class="d-flex text-h5">
                <v-icon color="success lighten-2" class="mr-2">mdi-check-circle-outline</v-icon>
                Security
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <v-sheet rounded="lg" class="pa-6 text-start network-card">
        <div class="text-body-1 font-weight-regular grey--text">Network support</div>
        <div class="d-flex text-caption mt-6">
          <v-img max-width="32px" contain :src="require(`@/assets/binance.svg`)" />
          <div
            class="ml-2 align-self-center font-weight-medium"
            :class="$vuetify.breakpoint.smAndUp ? 'text-lg' : 'text-h5'"
            style="line-height: 150%"
          >
            Binance Smart Chain network
          </div>
        </div>
        <!-- <v-btn rounded color="primary" block large depressed @click="connectWallet" :loading="connectingWallet" class="mb-6"
      >Connect Wallet</v-btn
    > -->
      </v-sheet>
      <v-sheet rounded="lg" class="pa-6 text-start mt-16">
        <v-row>
          <v-col md="6" sm="7" cols="12">
            <div
              class="mb-5"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h4 font-weight-bold' : 'text-lg font-weight-medium'"
            >
              B - Token Locker
            </div>
            <div class="text-medium font-weight-regular mb-10 grey--text">
              Token can be locked for a configurable period time, supported all ERC20 tokens including Rebasing and
              Deflationary mechanisms.
            </div>
            <div
              class="mb-5"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h4 font-weight-bold' : 'text-lg font-weight-medium'"
            >
              B - Liquidity Locker
            </div>
            <div class="text-medium font-weight-regular mb-6 grey--text">Lock liquidity Tokens</div>
            <router-link to="/locker">
              <div
                color="primary"
                class="cursor-pointer text-medium font-weight-regular primary--text d-flex align-center hand"
              >
                Go to B-Locker <v-icon size="18" color="primary" class="ml-1">mdi-open-in-new</v-icon>
              </div>
            </router-link>
          </v-col>
          <v-col md="6" sm="5" cols="12" class="d-flex justify-center align-center locker-card">
            <v-img
              contain
              :src="require(`@/assets/locker.svg`)"
              :max-height="$vuetify.breakpoint.smAndUp ? '217px' : '167px'"
            />
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet rounded="lg" class="pa-6 text-start mt-16">
        <v-row>
          <v-col md="6" sm="5" cols="12" class="d-flex justify-center align-center bulk-sender-card">
            <v-img
              contain
              :src="require(`@/assets/bulk-sender.svg`)"
              :max-height="$vuetify.breakpoint.smAndUp ? '180px' : '130px'"
            />
          </v-col>
          <v-col md="6" sm="7" cols="12">
            <div
              class="mb-5"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h4 font-weight-bold' : 'text-lg font-weight-medium'"
            >
              B - BulkSender
            </div>
            <div class="text-medium font-weight-regular mb-6 grey--text">
              Sending tokens to a list of multiple addresses at the same time quickly and efficiently with 4 simple
              operations.
            </div>
            <router-link to="/bulksender">
              <div
                color="primary"
                class="cursor-pointer text-medium font-weight-regular primary--text d-flex align-center hand"
              >
                Go to B-BulkSender<v-icon size="18" color="primary" class="ml-1">mdi-open-in-new</v-icon>
              </div>
            </router-link>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet rounded="lg" class="pa-6 text-start mt-16">
        <v-row>
          <v-col md="6" sm="7" cols="12">
            <div
              class="mb-5"
              :class="$vuetify.breakpoint.smAndUp ? 'text-h4 font-weight-bold' : 'text-lg font-weight-medium'"
            >
              B - MultiClaimer
            </div>
            <div class="text-medium font-weight-regular mb-10 grey--text">
              Creating and sending token to a list of the multiple address according to the set time.
            </div>
            <div class="text-medium font-weight-regular mb-6 grey--text">
              Multiple address check & claim on public page.
            </div>
            <router-link to="claimer/pools">
              <div
                color="primary"
                class="cursor-pointer text-medium font-weight-regular primary--text d-flex align-center hand"
              >
                Go to B-Multiclaimer<v-icon size="18" color="primary" class="ml-1">mdi-open-in-new</v-icon>
              </div>
            </router-link>
          </v-col>
          <v-col md="6" sm="5" cols="12" class="d-flex justify-center align-center multi-claimer-card">
            <v-img
              contain
              :src="require(`@/assets/multi-claimer.svg`)"
              :max-height="$vuetify.breakpoint.smAndUp ? '191px' : '140px'"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <div
        class="text-center"
        style="width: 100%"
        :style="$vuetify.breakpoint.smAndUp ? 'margin-top: 180px;' : 'margin-top: 120px'"
      >
        <div
          class="mb-2"
          :class="$vuetify.breakpoint.smAndUp ? 'text-h3 font-weight-black' : 'text-h4 font-weight-bold'"
        >
          Start in seconds
        </div>
        <div class="mb-0 font-weight-regular" :class="$vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-h6 '">
          Connect your crypto wallet to start using the app in seconds. <br />
          And no registration needed.
        </div>
      </div>
    </v-container>
    <div class="footer">
      <v-btn rounded class="primary footer" @click="connectWallet" :loading="connectingWallet" :disabled="!!account">
        {{ account ? 'Connected' : 'Connect wallet' }}</v-btn
      >
    </div>
    <div class="secondary lighten-2">
      <v-container class="brand-card secondary lighten-2 text-start text-body-1 font-weight-regular grey--text">
        © 2021 BSClaunch All rights reserved
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState('auth', ['account', 'connectingWallet']),
  },
  methods: {
    ...mapActions('auth', ['connectMetamask']),
    async connectWallet() {
      try {
        await this.connectMetamask()
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
<style scoped>
.home-container {
  max-width: 1170px;
  padding-bottom: 0;
}
.intro-container {
  background-image: url('../assets/backgrounds/home-intro-bg.svg') !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.intro-frame {
  margin-bottom: 150px;
  margin-top: 100px;
}
.intro-frame-sm {
  margin-bottom: 110px;
  margin-top: 60px;
}
.network-card {
  background-image: url('../assets/backgrounds/home-network-bg.svg') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}
.locker-card {
  background-image: url('../assets/backgrounds/home-locker-bg.svg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
}
.bulk-sender-card {
  background-image: url('../assets/backgrounds/home-bulk-sender-bg.svg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
}
.multi-claimer-card {
  background-image: url('../assets/backgrounds/home-multi-claimer-bg.svg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
}
.footer {
  padding-bottom: 106px;
  padding-top: 53px;
  background-image: url('../assets/backgrounds/home-footer-bg.svg') !important ;
  background-repeat: no-repeat;
  background-size: cover;
}
.brand-card {
  max-width: 1170px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>

